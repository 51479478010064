import React, { useState, useEffect } from 'react';
import './ContractAdminForm.css';
import ReactQuill from 'react-quill';
import { Navigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles
import { validateSession, handleLogin, getCookie } from '../../../utils/authUtils';
import { Route } from 'react-router-dom';

function ContractForm() {
    // State variables for form fields and message
    const [username, setUsername] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status

    const [pName, setpName] = useState('');
    const [pCompensation, setpCompensation] = useState('');
    const [pStreaming, setpStreaming] = useState('');
    const [contractText, setContractText] = useState(''); // State to store custom contract text
    const [contractPreset, setContractPreset] = useState('null');

    const [currentDate, setCurrentDate] = useState("");

    const [contractDetails, setContractDetails] = useState({
        playerName: pName,
        streamingHours: pStreaming,
        monthlyCompensation: pCompensation,
        playerAddress: 'Player Address', // Example static data for address
        playerCity: 'Player City', // Example static data for city
        currentDate: new Date().toLocaleDateString('de-DE'),
      });

    useEffect(() => {
        const today = new Date();
        const formattedDate = `${today.getDate().toString().padStart(2, "0")}.${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}.${today.getFullYear()}`;
        setCurrentDate(formattedDate);

        // Validate session on component mount
        const checkSession = async () => {
            const isValidSession = await validateSession();
            setIsLoggedIn(isValidSession);
        };

        checkSession();
    }, []);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const loginSuccess = await handleLogin(username, authCode);
        setIsLoading(false);
        setIsLoggedIn(loginSuccess);
        setMessage(loginSuccess ? 'Login successful!' : 'Login failed. Please try again.');
    };

    //const setPresetFromLink = () => {
    //    // Get the current URL
    //    const url = new URL(window.location.href);
    //
    //    // Extract the value of the "preset" query parameter
    //    const preset = url.searchParams.get("preset");
    //
    //    // Set the contract preset, defaulting to "Test" if the preset is null
    //    setContractPreset(preset || null);
    //};

    const uploadContract = async (e) => {
        const sessionId = getCookie('connect.sid');
        if (!sessionId) return;
        const contractHTML = document.getElementsByClassName('ql-editor')[0].innerHTML;
        // Generate HTML File
        const file = new File([contractHTML], 'contract.html', { type: 'text/html' });
        // Upload the File inside of a Post Request
        const formData = new FormData();
        formData.append('contract', file);
        const response = await fetch(`https://api.prgpurge.com/prg/api/upload-contract?cookie=${sessionId}&partner=${contractDetails.playerName}`, {
            method: 'POST',
            body: formData,
        });

        const body = await response.json();
        
        //console.log('Contract uploaded:', response);
        if (response.ok) {
            setMessage('Contract uploaded successfully! The Code was copied to your Clipboard. \nContract Code: '+body.contractCode);
            navigator.clipboard.writeText(body.contractCode).catch(err => {
                console.error('Failed to copy contract code to clipboard: ', err);
            });
        }
    }

    const downloadContract = () => {
        const contractHTML = document.getElementsByClassName('ql-editor')[0].innerHTML;
        // Create a Blob from the contract HTML
        const blob = new Blob([contractHTML], { type: 'text/html' });
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'contract.html'; // File name for the download
        // Trigger the download
        link.click();
        // Clean up the URL object
        URL.revokeObjectURL(link.href);
    };

    function parsePresetContract(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
    
        // Helper function to extract text or inner HTML safely
        const extractContent = (element, asHTML = false) =>
            element ? (asHTML ? element.innerHTML.trim() : element.textContent.trim()) : "";
    
        // Extract title
        const titleElement = doc.querySelector("h1");
        const title = `<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }}>${extractContent(titleElement, true)}</h1>`;
    
        // Extract "between" content
        let between = "";
        if (titleElement) {
            // First <p> line below the title
            const firstParagraph = titleElement.nextElementSibling;
            if (firstParagraph && firstParagraph.tagName.toLowerCase() === "p") {
                between += extractContent(firstParagraph, true);
            }
    
            // Second <p> line below the title
            const secondParagraph = firstParagraph?.nextElementSibling;
            if (secondParagraph && secondParagraph.tagName.toLowerCase() === "p") {
                between += "<br/>" + extractContent(secondParagraph, true);
            }
            between += "<br/>";
        }    
    
        // Extract sections
        const sections = [];
        const headings = doc.querySelectorAll("h2");
        headings.forEach((heading, index) => {
            const nextHeading = headings[index + 1];
            const sectionContent = [];
            let sibling = heading.nextElementSibling;
    
            while (sibling && sibling !== nextHeading) {
                sectionContent.push(extractContent(sibling, true));
                sibling = sibling.nextElementSibling;
            }
    
            sections.push({
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>${extractContent(heading, true)}</h2>`,
                section: heading.textContent
                    .toLowerCase()
                    .replace(/[^\w]+/g, "")
                    .replace(/ /g, "_"),
                text: sectionContent,
            });
        });
    
        // Construct the final object
        const contractObject = {
            title,
            between,
            titles: sections.map((section) => ({
                title: section.title,
                section: section.section,
            })),
        };
    
        // Add content for each section
        sections.forEach((section) => {
            contractObject[section.section] = { text: section.text };
        });
    
        return contractObject;
    }

    // QuillJS handler
    const handleQuillChange = (value) => {
        setContractText(value); // Update contract text
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
      
        // Update contract details state
        setContractDetails({ ...contractDetails, [name]: value });
      };
    
    const initializeContractText = async () => {
        var contractContent = await loadPreset();
        if (!contractContent) {
            console.error("Failed to load contract content for preset:", contractPreset);
            return;
        }
        let contractText = `${contractContent.title}`;
        contractText += `${contractContent.between}<br/><br/>`;
    
        // Loop through each title and section to build the contract text
        contractContent.titles.forEach(titleObj => {
            contractText += `${titleObj.title}`;
    
            // Get the corresponding content for the section
            const sectionContent = contractContent[titleObj.section];
    
            // Ensure there's content in the section and append it
            if (sectionContent) {
                // Render main text content if present
                if (sectionContent.text) {
                    contractText += `<p>`;
                    sectionContent.text.forEach(paragraph => {
                        contractText += `${paragraph}<br/>`;
                    });
                    contractText += `</p>`;
                }
    
                // Check for the new object structure
                if (sectionContent.newObject) {
                    contractText += `<p>`;
                    // Example: Render properties of the new object
                    sectionContent.newObject.forEach(newObjItem => {
                        contractText += `<strong>${newObjItem.heading}:</strong> ${newObjItem.detail}`;
                    });
                    contractText += `</p>`;
                }
            }
        });
    
        console.log(contractText); // Log the contract text for debugging purposes
        setContractText(contractText); // Set the contract text
    };

    async function loadPreset() {
        setContractText('Loading Preset...');
        if (contractPreset === 'null') return null;
        try {
            const response = await fetch(`https://api.prgpurge.com/prg/api/load-preset?preset=${contractPreset}`, {
              method: "GET",
            });
      
            if (response.status === 200) {
              var contractData = await response.text(); // Assuming HTML is returned as plain text
              console.log(parsePresetContract(contractData));
              contractData = contractData.replaceAll('<h1>', '<h1 style="text-align: center; font-size: 25px; margin-bottom: 20px;">');
              contractData = contractData.replaceAll('<h2>', '<h2 style="font-size: 16px; margin-top: 20px;">');
              contractData = contractData.replaceAll(`<p><br></p>`, '<br/>');
              return parsePresetContract(contractData);
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        initializeContractText(); // Initialize the contract text when the page loads
    }, [contractPreset]); // Re-run when the language changes

    return (
        <div className="contract-form-page-admin">
            <br></br>
            <br></br>
            <br></br>
            <h1 className="socials-title">Contract Form Admin Panel</h1>

            {((isLoggedIn || getCookie("connect.sid")) && contractPreset != 'null') ? (
                <div className="logged-in-container">
                    <p>Welcome <strong>PRG {getCookie("connect.user")}</strong>, you are Currently Creating a Contract based of the Preset:<br/> <strong>{contractPreset}</strong></p>
                    <div className="preset-back">
                        <button onClick={() => setContractPreset('null')}>Back</button>
                    </div>
                    <div className="contract-section">
                        <h3>Edit Contract Text</h3>
                        <ReactQuill
                            value={contractText}
                            onChange={handleQuillChange}
                            theme="snow"
                        />
                    </div>
                    <br/>
                    <p>The Signatures will always be displayed at the Contracts End</p>
                    <label>
                        Who is this Contract for?
                        <input
                            type="text"
                            name="playerName"
                            value={contractDetails.playerName}
                            onChange={handleInputChange}
                        />
                    </label>
                    <p>{message}</p>
                    <button onClick={() => downloadContract()}>Publish Contract</button>
                </div>
            ) : (
                <>
                    {(contractPreset != 'null') ? (
                        <Navigate to="/en/prg-admin" />
                    ) : (
                        <div>
                            <p>Please Select a Preset, on which the Contract is being Build off:</p>
                            <button onClick={() => setContractPreset('default_contract_de')}>Default Contract CC DE</button>
                            <button onClick={() => setContractPreset('default_contract_en')}>Default Contract CC EN</button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ContractForm;
