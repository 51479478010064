import React, { useState, useEffect } from 'react';
import TeamSection from '../../../components/team-container/TeamContainer.js';
import { loadTeams } from '../../../utils/authUtils';
import './Teams.css';

function Teams() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedGame, setSelectedGame] = useState('All'); // State to track the selected game
  const [teams, setTeams] = useState([]); // State for dynamically loaded teams
  const [message, setMessage] = useState('Loading Teams...');

  const valorantLOGO = "/images/valorantlogo.png";
  const fortniteLOGO = "/images/fortnitelogo.png";

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const loadedTeams = await loadTeams(); // Call the dynamic loading method
        setTeams(loadedTeams); // Set the loaded teams into state
        setMessage(''); // Clear the loading message when teams are loaded successfully
      } catch (error) {
        console.error('Failed to load teams:', error);
        setMessage('Failed to load teams. Please try again later.');
      }
    };

    fetchTeams(); // Trigger loading on mount
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1000);
  };

  function getExtraInfo(team) {
    return {
      socials : JSON.parse(team["extraInfo.socials"]) || null,
      proPlayer : team["extraInfo.proPlayer"] === 1 ? true : false || false,
      academyPlayer : team["extraInfo.academyPlayer"] === 1 ? true : false || false,
      talentPlayer : team["extraInfo.talentPlayer"] === 1 ? true : false || false,
    } || null;
  }

  const filteredTeams = selectedGame === 'All' ? teams : teams.filter(team => team.teamgame === selectedGame);

  return (
    <div className="page-container">
      <h1><br />Our Teams</h1>
      <div className="button-container">
        <button className="gameBtn" onClick={() => setSelectedGame('All')}>All</button>
        <button className="gameBtn" onClick={() => setSelectedGame('Valorant')}>Valorant</button>
        <button className="gameBtn" onClick={() => setSelectedGame('Fortnite')}>Fortnite</button>
      </div>
      <p>{message}</p>
      <div className="container">
        <div className="grid-container-teams">
          {filteredTeams.map((team, index) => (
            <React.Fragment key={index}>
              <TeamSection
                gameSrc={team.gameSrc}
                extraInfo={getExtraInfo(team)}
                team={team.is_team === 1 ? true : false}
                logoSrc={team.logoSrc}
                teamgame={team.teamgame}
                teamName={team.teamName}
                teamdesc={team.teamdesc}
                leaderNames={JSON.parse(team.leaderNames)}
                coachNames={JSON.parse(team.coachNames)}
                playerNames={JSON.parse(team.playerNames)}
                ersatzNames={JSON.parse(team.ersatzNames)}
                tryoutNames={JSON.parse(team.tryoutNames)}
                onCopy={() => handleCopy(team.onCopy)}
                showPopup={showPopup}
                isInAdminPanel={false}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Teams;
