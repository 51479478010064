import React, { useState } from 'react';
import './Start.css';
import DiscordButton from '../../../components/discord-button/DiscordButton';
import GridBackground from '../../../components/grid-background/GridBackground';
import FAQSection from '../../../components/faq-section/FAQSection';

const iconClassMapping = {
  tiktok: 'fab fa-tiktok',
  discord: 'fab fa-discord',
  twitter: 'fab fa-twitter',
  instagram: 'fab fa-instagram',
  youtube: 'fab fa-youtube',
  twitch: 'fab fa-twitch',
};

const faqs = [
  {
    question: 'What is Purge eSports?',
    answer: 'Purge eSports is an eSports organization that brings many different people together and helps them improve and grow.'
  },
  {
    question: 'How can I join Purge eSports?',
    answer: 'You can join Purge eSports by either contacting the team leaders themselves or by creating a ticket on our Discord server.'
  },
  {
    question: 'In which games is Purge eSports active?',
    answer: 'Purge eSports is active in Valorant and Fortnite'
  }
];

const displayedPlayers = [
  {
    name: 'KillstealGmbH',
    role: 'Owner',
    Image: '/images/killstealpic.webp',
    socials: {
      tiktok: 'https://www.tiktok.com/@killstealgmbh',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: 'https://www.youtube.com/@KillstealGmbH',
      twitch: 'https://www.twitch.tv/killstealgmbh'
    }
  },
  {
    name: 'HerobrineTVv',
    role: 'Co Owner / Developer',
    Image: '/images/HerobrineTVv.png',
    socials: {
      tiktok: 'https://www.tiktok.com/@herobrinetvv',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: 'https://www.youtube.com/@HerobrineTVv',
      twitch: 'https://www.twitch.tv/herobrinetvv'
    }
  },
  {
    name: 'Lowe',
    role: 'Admin',
    Image: '/images/Owner-PB-Banner.gif',
    socials: {
      tiktok: '',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: '',
      twitch: ''
    }
  },
  {
    name: 'JuliChan',
    role: 'Admin / Management',
    Image: '/images/Owner-PB-Banner.gif',
    socials: {
      tiktok: 'https://www.tiktok.com/@gamermick_youtube',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: 'https://www.youtube.com/@julichan7460',
      twitch: ''
    }
  },
  {
    name: 'Agaraphobie',
    role: 'Teamleitung / Developer',
    Image: '/images/IMG_6968.png',
    socials: {
      tiktok: 'https://www.tiktok.com/@agaraph0bie?_t=8rYjFNovZWT&_r=1',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: '',
      twitch: ''
    }
  },
  {
    name: 'NightyOnly',
    role: 'Developer',
    Image: '/images/NightyOnly.png',
    socials: {
      tiktok: '',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: '',
      twitch: ''
    }
  },
  {
    name: 'Grit',
    role: 'Developer',
    Image: '/images/Owner-PB-Banner.gif',
    socials: {
      tiktok: '',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: '',
      twitch: ''
    }
  },
  {
    name: 'Faxx',
    role: 'Management',
    Image: '/images/Owner-PB-Banner.gif',
    socials: {
      tiktok: '',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: '',
      twitch: ''
    }
  },
  {
    name: 'Sinze',
    role: 'Management',
    Image: '/images/IMG_7303.png',
    socials: {
      tiktok: '',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: '',
      twitch: ''
    }
  }

  //,
  //{
  //  name: 'HerobrineTVv',
  //  role: 'Co Owner',
  //  Image: '/images/Owner-PB-Banner.gif',
  //  socials: {
  //    tiktok: 'https://www.tiktok.com/@purgeesports',
  //    discord: 'https://discord.gg/purgeesports',
  //    twitter: 'https://twitter.com/purgeesports',
  //    instagram: 'https://www.instagram.com/purgeesports/',
  //    youtube: 'https://www.instagram.com/purgeesports/',
  //    twitch: 'https://www.instagram.com/purgeesports/'
  //  }
  //}
]

function scrollLeft() {
  const container = document.querySelector('.players-container');
  container.scrollBy({ left: -200, behavior: 'smooth' });
}

function scrollRight() {
  const container = document.querySelector('.players-container');
  container.scrollBy({ left: 200, behavior: 'smooth' });
}

function Start() {
  return (
    <div className="page-container">
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        rel="stylesheet"
      />
      <div className="container">
        <GridBackground />
        <div className="grid-container">
          <div className="grid-item info-section">
            <div className="tagline">Valorant & Fortnite</div>
            <h1 className="text-appear-animation">Purge <br />eSports</h1>
            <p>
            At Purge eSports, we help players and coaches find the perfect team. Whether you're an experienced player or an aspiring coach, we provide the platform and support you need to be successful.
            </p>
            <div className="buttons">
              <DiscordButton />
              <button className='match-history'>Match History</button>
            </div>
          </div>
          <div className="grid-item trikot-section">
            <div className="blue-circle"></div>
            <img src="/images/JerseyPrintfileFront-Purge.png" alt="Team Jersey" className="jersey-image" />
          </div>
          <div className="grid-item-mid info-section">
          <h2>Our Team</h2>
            <div className="scroll-container">
              <button className="scroll-button left" onClick={scrollLeft}>&lt;</button>
              <div className="players-container">
                {displayedPlayers.map((player, index) => (
                  <div key={index} className="player-card">
                    <img src={player.Image} alt={player.name} className="player-image" style={{ width: '299.25px', height: '485px', objectFit: 'cover' }} />
                    <div className="player-info">
                      <h3>{player.name}</h3>
                      <p>{player.role}</p>
                      <div className="social-icons">
                        {Object.entries(player.socials).map(([name, url], idx) => (
                          url && (
                            <a key={idx} href={url} target="_blank" rel="noopener noreferrer" className="social-link">
                              <i className={iconClassMapping[name]}></i>
                            </a>
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="scroll-button right" onClick={scrollRight}>&gt;</button>
            </div>
          </div>
          {/* Empty Grid Setion */}
          <div  className="grid-item info-section"></div>
          <div className="grid-item-right info-section">
            <div className="tagline">Questions and Answers</div>
            <h1>FAQ</h1>
            <FAQSection faqs={faqs} />
          </div>
        </div>
      </div>
    </div>
  );
}

// Mitglieder Images wie auf cgn: https://www.cgn.gg/

export default Start;
