import React, { useState, useEffect } from 'react';
import './AdminManageTeams.css';
import { Navigate } from 'react-router-dom';
import { validateSession, getCookie, loadTeams, editTeam, createTeam, deleteTeam } from '../../../utils/authUtils';
import { IconButton, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Add, Edit, Save, Cancel, Delete } from '@mui/icons-material';
import TeamSection from '../../../components/team-container/TeamContainer.js';
import TagInput from '../../../components/tag-input/TagInput.js';
import { v4 as uuidv4 } from 'uuid'; // To generate unique temporary IDs

function ManageTeams() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [message, setMessage] = useState('Loading Teams...');
    const [teams, setTeams] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [selectedGame, setSelectedGame] = useState('All'); // State to track the selected game
    const [showPopup, setShowPopup] = useState(false);
    const [oldTeams, setOldTeams] = useState([]);
    const [addingNewTeam, setAddingNewTeam] = useState(false); // New state to track if a new team is being added
    const [filteredTeams, setFilteredTeams] = useState([]);

    useEffect(() => {
        const checkSession = async () => {
            const isValidSession = await validateSession();
            setIsLoggedIn(isValidSession);
        };

        checkSession();
        fetchTeams();
    }, []);

    useEffect(() => {
        reFilterTeams(teams);
    }, [teams]);

    const fetchTeams = async () => {
        try {
            const data = await loadTeams();
            setMessage('')
            setTeams(data);
        } catch (error) {
            console.error('Error fetching teams:', error);
            setMessage('Failed to load teams');
        }
    };

    const handleSave = async (id) => {
        const index = teams.findIndex((team) => team.id === id);
        if (index !== -1) {
            const team = teams[index];
    
            // Construct the object to send to the backend
            const backendObject = {
                gameId: team.gameId,
                id: team.id,
                teamName: team.teamName,
                teamdesc: team.teamdesc,
                is_team: team.is_team,
                logoSrc: team.logoSrc,
                teamgame: team.teamgame,
                leaderNames: JSON.parse(team.leaderNames || '[]'),
                coachNames: JSON.parse(team.coachNames || '[]'),
                playerNames: JSON.parse(team.playerNames || '[]'),
                ersatzNames: JSON.parse(team.ersatzNames || '[]'),
                tryoutNames: JSON.parse(team.tryoutNames || '[]'),
                onCopy: team.onCopy,
                extraInfo: {
                    socials: JSON.parse(team['extraInfo.socials'] || '{}'),
                    proPlayer: team['extraInfo.proPlayer'] === 1,
                    academyPlayer: team['extraInfo.academyPlayer'] === 1,
                    talentPlayer: team['extraInfo.talentPlayer'] === 1,
                },
            };
    
            try {
                // Replace this with your actual API call
                if (team.isNew === true) {
                    createTeam(team.id, backendObject);
                } else {
                    editTeam(team.id, backendObject);
                }
                //console.log('Saving to backend:', backendObject);
    
                // Simulating save operation
                const newTeams = [...teams];
                setOldTeams(newTeams);
                setTeams(newTeams);
                setEditingId(null);
            } catch (error) {
                console.error('Error saving team:', error);
            }
        } else {
            console.error(`Team with id ${id} not found`);
        }
    };
    

    const handleCancel = (index) => {
        const newTeams = [...teams];
        newTeams[index] = { ...oldTeams[index] }; // Restore from backup
        setTeams(newTeams);
        setEditingId(null);
    };

    const handleDelete = (id) => {
        const index = teams.findIndex(team => team.id === id);
        if (index !== -1) {
            const newTeams = [...teams];
            if (newTeams[index].isNew === true) { 
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }, 100);
            } else {
                deleteTeam(id);
            }
            newTeams.splice(index, 1);
            setOldTeams(newTeams);
            setTeams(newTeams);
            setEditingId(null);
        } else {
            console.error(`Team with id ${id} not found`);
        }
    }
    
    const getPlayerType = (team) => {
        if (team['extraInfo.proPlayer'] === 1) return 'pro';
        if (team['extraInfo.academyPlayer'] === 1) return 'academy';
        if (team['extraInfo.talentPlayer'] === 1) return 'talent';
        return '';
    };

    const onCopy = (team, index) => {
        const newTeams = [...teams];
        const backupTeams = [...oldTeams];
        
        // Save the backup only once when entering edit mode
        if (!team.isEditing) {
            backupTeams[index] = { ...team };
        }
        
        newTeams[index].isEditing = true;
        setOldTeams(backupTeams);
        setTeams(newTeams);
        setEditingId(team.id);
    };
    
    function getExtraInfo(team) {
      return {
        socials : JSON.parse(team["extraInfo.socials"]) || null,
        proPlayer : team["extraInfo.proPlayer"] === 1 ? true : false || false,
        academyPlayer : team["extraInfo.academyPlayer"] === 1 ? true : false || false,
        talentPlayer : team["extraInfo.talentPlayer"] === 1 ? true : false || false,
      } || null;
    }

    const renderSocialField = (team, index, field, label) => (
        <TextField
            id={`team-${field}-edit`}
            label={label}
            value={getExtraInfo(team).socials?.[field] || ''}
            onChange={(e) => {
                const newTeams = [...teams];
                const currentSocials = JSON.parse(newTeams[index]['extraInfo.socials'] || '{}');
                if (e.target.value === '') {
                    // Remove the field if it's empty
                    delete currentSocials[field];
                } else {
                    // Update the field with the new value
                    currentSocials[field] = e.target.value;
                }
                // Only stringify and update if there are any social fields left
                if (Object.keys(currentSocials).length > 0) {
                    newTeams[index]['extraInfo.socials'] = JSON.stringify(currentSocials);
                } else {
                    // If all social fields are empty, set extraInfo.socials to null or remove it
                    delete newTeams[index]['extraInfo.socials'];
                }
                setTeams(newTeams);
            }}
        />
    );
    
    const renderTagInput = (team, index, field, label) => (
        <TagInput
            label={label}
            value={JSON.parse(team[field]) || []}
            onChange={(newValue) => {
                const newTeams = [...teams];
                newTeams[index][field] = JSON.stringify(newValue);
                setTeams(newTeams);
            }}
        />
    );


    function reFilterTeams(updatedTeams) {
        const filtered = selectedGame === 'All' 
            ? updatedTeams.sort((a, b) => {
                // Zuerst nach `isNew` sortieren (true zuerst, dann false)
                return b.isNew - a.isNew; // true wird als 1 behandelt, false als 0
            })
            : updatedTeams.filter(team => team.teamgame === selectedGame).sort((a, b) => {
                // Zuerst nach `isNew` sortieren (true zuerst, dann false)
                return b.isNew - a.isNew; // true wird als 1 behandelt, false als 0
            });
    
        setFilteredTeams(filtered);
    }

    const renderTeamItem = (team, index) => {
        const isEditing = team.id === editingId;

        return (
            <div>
                <React.Fragment key={index}>
                        {team.isEditing ? (
                            <div className="grid-item-teams">
                                {/*<div className="team-edit-form grid-subcontainer-teams">*/}
                                <div className='header-section-edit-teams'>
                                    {team.is_team ? (
                                        <TextField
                                            id="team-name-edit"
                                            label="Team Name"
                                            value={team.teamName}
                                            onChange={(e) => {
                                                const newTeams = [...teams];
                                                newTeams[index].teamName = e.target.value;
                                                setTeams(newTeams);
                                            }}
                                        />
                                    ):(
                                    <TextField
                                        id="team-name-edit"
                                        label="Player Name"
                                        value={team.teamName}
                                        onChange={(e) => {
                                            const newTeams = [...teams];
                                            newTeams[index].teamName = e.target.value;
                                            setTeams(newTeams);
                                        }}
                                    />
                                    )}


                                    {team.is_team ? (
                                        <TextField
                                            id="team-leaderdc-edit"
                                            label="Leader Discord"
                                            value={team.onCopy}
                                            onChange={(e) => {
                                                const newTeams = [...teams];
                                                newTeams[index].onCopy = e.target.value;
                                                setTeams(newTeams);
                                            }}
                                        />
                                    ) : (
                                        <FormControl fullWidth>
                                            <InputLabel id="player-type-label">Player Type</InputLabel>
                                            <Select
                                                labelId="player-type-label"
                                                id="player-type-select"
                                                value={team.playerType || getPlayerType(team)}
                                                label="Player Type"
                                                onChange={(e) => {
                                                    const newTeams = [...teams];
                                                    newTeams[index].playerType = e.target.value;
                                                    ['pro', 'academy', 'talent'].forEach(type => {
                                                        newTeams[index][`extraInfo.${type}Player`] = e.target.value === type ? 1 : 0;
                                                    });
                                                    setTeams(newTeams);
                                                }}
                                            >
                                                {['pro', 'academy', 'talent'].map(type => (
                                                    <MenuItem key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)} Player</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>

                                <div className='info-section-teams'>
                                    {team.is_team ? (
                                    <TextField
                                        id="team-desc-edit"
                                        label="Team Description"
                                        value={team.teamdesc}
                                        onChange={(e) => {
                                            const newTeams = [...teams];
                                            newTeams[index].teamdesc = e.target.value;
                                            setTeams(newTeams);
                                        }}
                                    />
                                    ):(
                                    <TextField
                                        id="team-desc-edit"
                                        label="Player Description"
                                        value={team.teamdesc}
                                        onChange={(e) => {
                                            const newTeams = [...teams];
                                            newTeams[index].teamdesc = e.target.value;
                                            setTeams(newTeams);
                                        }}
                                    />
                                    )}
                                    
                                    {team.is_team ? (
                                        <div className='player-section-edit-teams'>
                                            {renderTagInput(team, index, 'leaderNames', 'Leaders (Press Enter to add New)')}
                                            {renderTagInput(team, index, 'coachNames', 'Coaches (Press Enter to add New)')}
                                            {renderTagInput(team, index, 'playerNames', 'Players (Press Enter to add New)')}
                                            {renderTagInput(team, index, 'ersatzNames', 'Substitutes (Press Enter to add New)')}
                                            {renderTagInput(team, index, 'tryoutNames', 'Tryouts (Press Enter to add New)')}
                                        </div>
                                    ) : (
                                        <div>
                                            <p>Socials</p>
                                            <div className='player-section-edit-teams'>
                                                {renderSocialField(team, index, 'tracker', 'Tracker')}
                                                {renderSocialField(team, index, 'twitter', 'Twitter')}
                                                {renderSocialField(team, index, 'twitch', 'Twitch')}
                                                {renderSocialField(team, index, 'youtube', 'YouTube')}
                                                {renderSocialField(team, index, 'tiktok', 'TikTok')}
                                                {renderSocialField(team, index, 'instagram', 'Instagram')}
                                                {renderSocialField(team, index, 'discord', 'Discord')}
                                            </div>
                                        </div>
                                    )}
                                    
                                </div>

                                {/* Add more fields here as needed */}
                                {team.isNew ? (
                                    <div>
                                        <Button onClick={() => {
                                            handleSave(team.id);
                                            team.isEditing = false;
                                            team.isNew = false;
                                        }} startIcon={<Save />}>
                                            Save
                                        </Button>
                                        <Button onClick={() => {
                                            team.isEditing = false;
                                            handleDelete(team.id);
                                        }} startIcon={<Cancel />}>
                                            Cancel
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        <Button onClick={() => {
                                            handleSave(team.id);
                                            team.isEditing = false;
                                            team.isNew = false;
                                        }} startIcon={<Save />}>
                                            Save
                                        </Button>
                                        <Button
                                            onClick={() => handleCancel(index)}
                                            startIcon={<Cancel />}
                                        >
                                            Cancel
                                        </Button>
                                        <Button onClick={() => {
                                            handleDelete(team.id);
                                            team.isEditing = false;
                                        }} startIcon={<Delete />}>
                                            Delete
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <TeamSection
                                gameSrc={team.gameSrc}
                                extraInfo={getExtraInfo(team)}
                                team={team.is_team === 1 ? true : false}
                                logoSrc={team.logoSrc}
                                teamgame={team.teamgame}
                                teamName={team.teamName}
                                teamdesc={team.teamdesc}
                                leaderNames={JSON.parse(team.leaderNames)}
                                coachNames={JSON.parse(team.coachNames)}
                                playerNames={JSON.parse(team.playerNames)}
                                ersatzNames={JSON.parse(team.ersatzNames)}
                                tryoutNames={JSON.parse(team.tryoutNames)}
                                onCopy={() => onCopy(team, index)}
                                showPopup={showPopup}
                                isInAdminPanel={true}
                            />
                        )}
                    </React.Fragment>
            </div>
        );
    };

    const handleAddNewTeam = (game) => {
        //if (editingId !== null) {
        //    setMessage('Please finish editing the current team before adding a new one!');
        //    return;
        //}
        const tempId = uuidv4(); // Generate a unique temporary ID

        const newTeam = {
            id: tempId,
            teamName: "",
            teamdesc: "",
            is_team: false,
            playerType: "",
            logoSrc: "/logo192.png",
            leaderNames: JSON.stringify([]),
            coachNames: JSON.stringify([]),
            playerNames: JSON.stringify([]),
            ersatzNames: JSON.stringify([]),
            tryoutNames: JSON.stringify([]),
            ["extraInfo.proPlayer"]: 0,
            ["extraInfo.academyPlayer"]: 0,
            ["extraInfo.talentPlayer"]: 0,
            ["extraInfo.socials"]: JSON.stringify({
                tracker: "",
                twitter: "",
                twitch: "",
                youtube: "",
                tiktok: "",
                instagram: "",
                discord: "",
            }),
            isEditing: true,
            isNew: true, // Flag to identify new teams
            teamgame: game, // Assign the game to the new team
            gameId: 1
        };

        switch (game) {
            case 'Valorant':
                newTeam.gameSrc = "/images/valorantlogo.png";
                newTeam.gameId = 1;
                newTeam.is_team = 1;
                break;
            case 'Fortnite':
                newTeam.gameSrc = "/images/fortnitelogo.png";
                newTeam.gameId = 2;
                newTeam.is_team = 0;
                break;
            default:
                newTeam.gameSrc = "/images/valorantlogo.png";
                newTeam.gameId = 1;
                newTeam.is_team = 1;
        }

        const updatedTeams = [...teams, newTeam];
        setTeams(updatedTeams);
        setEditingId(tempId);
        setAddingNewTeam(true);
    
        // Re-filter teams
        reFilterTeams(updatedTeams);

        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    };

    return (
        <div className="team-management-page-admin">
            <br />
            <br />
            <br />
            <h1 className="socials-title">Player Management Panel</h1>

            {(isLoggedIn || getCookie("connect.sid")) ? (
                <div className="logged-in-container">
                    <p>Welcome <strong>PRG {getCookie("connect.user")}</strong></p>
                    {/*
                    <div className="button-container">
                        <button className="gameBtn" onClick={() => {
                            if (editingId === null) {
                                setSelectedGame('All')
                            } else {
                                setMessage('Please finish Editing the current Team before switching Filters!')
                            }}
                        }>All</button>
                        <button className="gameBtn" onClick={() => {
                            if (editingId === null) {
                                setSelectedGame('Valorant')
                            } else {
                                setMessage('Please finish Editing the current Team before switching Filters!')
                            }}
                        }>Valorant</button>
                        <button className="gameBtn" onClick={() => {
                            if (editingId === null) {
                                setSelectedGame('Fortnite')
                            } else {
                                setMessage('Please finish Editing the current Team before switching Filters!')
                            }}
                        }>Fortnite</button>
                    </div>
                    */}
                    <br />
                    <p>{message}</p>
                    <Button
                        onClick={() => handleAddNewTeam("Valorant")}
                        startIcon={<Add />}
                    >
                        Add Valorant Team
                    </Button>
                    <Button
                        onClick={() => handleAddNewTeam("Fortnite")}
                        startIcon={<Add />}
                    >
                        Add Fortnite Player
                    </Button>
                    {filteredTeams.map((team, index) => renderTeamItem(team, index))}
                </div>
            ) : (
                <Navigate to="/en/prg-admin" />
            )}
        </div>
    );
}

export default ManageTeams;