const db = "prod"; // 'prod' or 'dev'

export const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === name) return value;
    }
    return null;
};

export const validateSession = async () => {
    const sessionId = getCookie('connect.sid');
    if (!sessionId) {
        return false;
    }

    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/validate-session?cookie=${sessionId}&database=${db}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId }),
        });

        const result = await response.json();

        if (response.ok && result.valid) {
            return true;
        } else {
            deleteCookie('connect.sid');
            deleteCookie('connect.user');
            window.location.href = '/en/prg-admin'; // Redirect to AdminMainMenu
            //window.location.reload()
            return false;
        }
    } catch (error) {
        console.error('Error validating session:', error);
        return false;
    }
};

export const deleteCookie = (name, path = '/') => {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const setCookie = (name, value, maxAge, path = '/', sameSite = 'Strict') => {
    const expiration = new Date(Date.now() + maxAge).toUTCString();
    document.cookie = `${name}=${value}; path=${path}; expires=${expiration}; SameSite=${sameSite}`;
};

export const handleLogin = async (username, authCode) => {
    if (!username || !authCode) {
        return { success: false, message: 'Username and Authenticator Code are required!' };
    }

    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/login?database=${db}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, authCode }),
        });

        const result = await response.json();

        if (response.ok) {
            if (result.cookie) {
                const maxAge = 60 * 60 * 1000 * 4; // 4 hours
                setCookie('connect.sid', result.cookie, maxAge);
                setCookie('connect.user', result.user, maxAge);
            }
            return { success: true, message: 'Login successful! You are now logged in.' };
        } else {
            return { success: false, message: result.message || 'Login failed.' };
        }
    } catch (error) {
        console.error('Error:', error);
        return { success: false, message: 'An error occurred during login.' };
    }
};

// Load Teams
export const loadTeams = async () => {
    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/load-teams?database=${db}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result = await response.json();

        return result || [];

    } catch (error) {
        console.error('Error loading Teams:', error);
        return [];
    }
};

// Mark Contract as Downloaded
export const markContractAsDownloaded = async (contractid) => {
    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/download-contract?contractid=${contractid}&database=${db}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result = await response.json();

        return result || [];

    } catch (error) {
        console.error('Error marking as Downloaded.', error);
        return [];
    }
};

// Edit Team
export const editTeam = async (teamid, teamObj) => {
    const sessionId = getCookie('connect.sid');
    validateSession();
    //console.log(JSON.stringify(teamObj))
    if (!sessionId) return false;
    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/edit-team?cookie=${sessionId}&database=${db}&team=${teamid}`, {
            method: 'POST', // Use PUT for updating resources
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(teamObj),
        });

        if (!response.ok) {
            throw new Error(`Failed to edit team: ${response.statusText}`);
        }

        const updatedTeam = await response.json();
        return updatedTeam; // Return the updated team object
    } catch (error) {
        console.error('Error editing team:', error);
        throw error; // Re-throw the error for the caller to handle
    }
};

// Create Team
export const createTeam = async (teamid, teamObj) => {
    const sessionId = getCookie('connect.sid');
    validateSession();
    //console.log(JSON.stringify(teamObj))
    if (!sessionId) return false;
    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/create-team?cookie=${sessionId}&database=${db}`, {
            method: 'POST', // Use PUT for updating resources
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(teamObj),
        });

        if (!response.ok) {
            throw new Error(`Failed to create team: ${response.statusText}`);
        }

        const updatedTeam = await response.json();
        return updatedTeam; // Return the updated team object
    } catch (error) {
        console.error('Error creating team:', error);
        throw error; // Re-throw the error for the caller to handle
    }
};

// Delete Team
export const deleteTeam = async (teamid) => {
    const sessionId = getCookie('connect.sid');
    validateSession();
    if (!sessionId) return false;
    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/delete-team?cookie=${sessionId}&database=${db}&team=${teamid}`, {
            method: 'DELETE', // Use PUT for updating resources
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to delete team: ${response.statusText}`);
        }

        const updatedTeam = await response.json();
        return updatedTeam; // Return the updated team object
    } catch (error) {
        console.error('Error deleting team:', error);
        throw error; // Re-throw the error for the caller to handle
    }
};
