import React, { useState } from 'react';
import { Chip, TextField, Box } from '@mui/material';
import './TagInput.css';

const TagInput = ({ value, onChange, label }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      const newValue = [...value, inputValue];
      onChange(newValue);
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete) => {
    const newValue = value.filter((tag) => tag !== tagToDelete);
    onChange(newValue);
  };

  return (
    <Box>
      <TextField
        label={label}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        fullWidth
        margin="normal"
        placeholder="Type and press Enter to add"
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {value.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            onDelete={() => handleDelete(tag)}
            sx={{
                color: 'white',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                },
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TagInput;