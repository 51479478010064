import './ContractFormIdBased.css';

import React, { useState, useEffect } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { markContractAsDownloaded } from '../../../utils/authUtils';
import html2pdf from "html2pdf.js";

function ContractFormIdBased() {
  const [ContractCode, setContractCode] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [contractHtml, setContractHtml] = useState(""); // New state to store HTML

  const [currentDate, setCurrentDate] = useState("");

  // Vertragsdetails
  const [contractDetails, setContractDetails] = useState({
    playerName: "",
    playerAddress: "",
    playerCity: "",
  });

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}.${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${today.getFullYear()}`;
    setCurrentDate(formattedDate);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Update contract details state
    setContractDetails({ ...contractDetails, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`https://api.prgpurge.com/prg/api/load-contract?contractid=${ContractCode}`, {
        method: "GET",
      });

      if (response.status === 200) {
        var contractData = await response.text(); // Assuming HTML is returned as plain text
        contractData = contractData.replaceAll('<h1>', '<h1 style="text-align: center; font-size: 25px; margin-bottom: 20px;">');
        contractData = contractData.replaceAll('<h2>', '<h2 style="font-size: 16px; margin-top: 20px;">');
        contractData = contractData.replaceAll('<p><br></p>', '<br/>');
        //contractData = contractData.replaceAll('{contractDetails.playerName}', `<span class="playerName">${contractDetails.playerName}</span>`);
        //contractData = contractData.replaceAll('{contractDetails.playerAddress}', contractDetails.playerAddress);
        //contractData = contractData.replaceAll('{contractDetails.playerCity}', contractDetails.playerCity);
        contractData = transformHtml(contractData);
        contractData += 
        `
        <div style="margin-top: 20px; text-align: left;">
          <p>
            <strong>Unterschriften:</strong>
          </p>
          <div class="signature-container">
            <div class="signature">
              <p>Adrian Kopp</p>
              <p>
                <strong>Owner of Purge eSports</strong>
              </p>
              <br>
              <br>
              <div class="signature-line">
                <span>Unterschrift:</span>
                <div class="line"></div>
              </div>
              <div class="signature-line">
                <br>
                <br>
                <span>Ort:</span>
                <div class="line"></div>
              </div>
              <p>Datum: <strong>${currentDate}</strong>
              </p>
            </div>
            <div class="signature">
              <p>{contractDetails.playerName}</p>
              <br>
              <br>
              <br>
              <br>
              <div class="signature-line">
                <span>Unterschrift:</span>
                <div class="line"></div>
              </div>
              <div class="signature-line">
                <br>
                <br>
                <span>Ort:</span>
                <div class="line"></div>
              </div>
              <p>Datum: <strong>${currentDate}</strong>
              </p>
            </div>
          </div>
        </div>
        `
        setContractHtml(contractData); // Save HTML to state        
        setIsAuthenticated(true);
      } else {
        setMessage("Wrong Code used or duplicate used.");
        //setMessage(response.json().message);
        setIsAuthenticated(false);
      }
    } catch (error) {
      setMessage("An error occurred.");
      console.warn("Error loading contract details: ", error);
    } finally {
      setLoading(false);
    }
  };

  function transformHtml(inputHtml) {
    // Create a DOM parser to work with the input HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(inputHtml, 'text/html');
    const body = doc.body;

    let newHtml = '';
    let currentParagraphContent = '';

    // Iterate over all child nodes of the body
    Array.from(body.childNodes).forEach((node) => {
        if (node.nodeName === 'P') {
            // Add content of the current <p> to the buffer
            if (currentParagraphContent) {
                currentParagraphContent += '<br/>';
            }
            currentParagraphContent += node.innerHTML.trim();
        } else {
            // If we encounter a non-<p> element, close the current paragraph
            if (currentParagraphContent) {
                newHtml += `<p>${currentParagraphContent}</p>`;
                currentParagraphContent = '';
            }
            // Add the non-<p> element as-is
            newHtml += node.outerHTML || node.textContent;
        }
    });

    // Close any remaining <p> content at the end
    if (currentParagraphContent) {
        newHtml += `<p>${currentParagraphContent}</p>`;
    }

    return newHtml;
}

  const generatePDF = () => {
    document.body.innerHTML = document.body.innerHTML.replaceAll('{contractDetails.playerName}', contractDetails.playerName);
    document.body.innerHTML = document.body.innerHTML.replaceAll('{contractDetails.playerAddress}', contractDetails.playerAddress);
    document.body.innerHTML = document.body.innerHTML.replaceAll('{contractDetails.playerCity}', contractDetails.playerCity);
    const element = document.getElementById("contract");

    const options = {
      margin: 2,
      marginBottom: 20,
      filename: "Vertrag_PurgeEsports.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait", autoPaging: true, },
    };

    markContractAsDownloaded(ContractCode);
    html2pdf().set(options).from(element).save();
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial" }}>
      {!isAuthenticated ? (
        <div className="contract-form-page before-code">
          <br/>
          <br/>
          <br/>
          <h1 className="socials-title">Contract Creator</h1>
          <p>
            If you see this page, you are most likely to get a Contract at PurgeeSports soon.
            We just need you to provide some basic information to create your Contract.
            Please fill out the form below and hit the "Print" button afterwards.
            Once you print the Contract out, sign it and send it to business@prgpurge.com.
            <br />
            <br />
            All your given information will not be sent to us. It will be used only for creating your Contract.
            <br /><br />
            Dont refresh the Contracts page, your code cant be used again.
          </p>
          <form onSubmit={handleLogin}>
            <div>
              <label>
                Insert Contract Code:
                <br/>
                <input className='contract-code-input'
                  type="text"
                  value={ContractCode}
                  onChange={(e) => setContractCode(e.target.value)}
                  required
                />
              </label>
              <p>{message}</p>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Loading..." : "Use Code"}
            </button>
          </form>
        </div>
      ) : (
        <div className="contract-form-page">
          <br/>
          <br/>
          <br/>
          <h2>Vertrag</h2>
          <div style={{ marginBottom: "20px" }}>
            <h3>Vertragsdetails bearbeiten</h3>
            <label>
              Name des Vertragspartners:
              <input
                type="text"
                name="playerName"
                value={contractDetails.playerName}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Adresse des Vertragspartners:
              <input
                type="text"
                name="playerAddress"
                value={contractDetails.playerAddress}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Ort des Vertragspartners:
              <input
                type="text"
                name="playerCity"
                value={contractDetails.playerCity}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div id="contract" style={{ padding: "10mm", border: "1px solid #ccc", color: "black", background: "white", fontSize: "12px", lineHeight: "1.5" }}
            dangerouslySetInnerHTML={{ __html: contractHtml }} // Render HTML here
          />
          <button className="PDFButton" onClick={generatePDF}>
            Download as PDF to Print
          </button>
        </div>
      )}
    </div>
  );
}

export default ContractFormIdBased;
